import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useNavigate } from "react-router-dom";
import BackButton from "../globals/BackButton";
import { stagingUrl } from "../../../src/helper";
import { Button } from "../../components-shadcn/ui/button";
import { useGetEntitiesMutation } from "../TipGroups/state/server-state/useTipGroupsServerState";
import { useGetSingleEntity } from "../TipGroups/state/server-state/useTipGroupsServerState";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../state/index";
import { AlertCircle, Loader2, Check, Timer, Clock } from "lucide-react";
import QRCode from "react-qr-code";
import { useState } from "react";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components-shadcn/ui/dialog";

const EntityInReview = () => {
  const { activeEntity, setActiveEntity, setEntityMembers, setEntityJARs } = useTipJarLocalState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getEntitiesMutation = useGetEntitiesMutation();
  const getSingleEntityMutation = useGetSingleEntity();
  const isGettingSingleEntity = getSingleEntityMutation.isLoading;
  const { logOut } = bindActionCreators(actions, dispatch);
  const [showPendingDialog, setShowPendingDialog] = useState(false);
  const [showApprovedDialog, setShowApprovedDialog] = useState(false);

  const handleGetSingleEntity = async (entityId) => {
    try {
      const data = await getSingleEntityMutation.mutateAsync(entityId);
      if (!data || !data?.data) {
        return;
      }
      const entity = data.data;
      setActiveEntity(entity);
      const status = entity.kyc_status;
      const comment = entity.kyc_comments;

      if (status === "PENDING" || status === "DOCUMENTS_UPLOADED") {
        setShowPendingDialog(true);
        return;
      }
      if (status === "REJECTED") {
        navigate("/rejected-entity/");
        return;
      }
      if (status === "APPROVED") {
        setShowApprovedDialog(true);
        return;
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to get entity");
    }
  };

  const handleCheckStatus = () => {
    if (!activeEntity) {
      toast.error("Entity not found");
      return;
    }
    handleGetSingleEntity(activeEntity.id);
  };

  const handleBack = () => {
    logOut();
    navigate("/login");
  };

  return (
    <div className="w-full min-h-screen bg-white p-4 flex flex-col">
      <div className="w-full px-4">
        <BackButton onPress={handleBack} />
      </div>

      <h1 className="text-2xl font-semibold text-[#50A2A7] mt-6 mb-8">
        Pending verification
      </h1>

      <div className="w-full flex flex-col items-center">
        {/* QR Code Container */}
        <div className="w-full max-w-md bg-[#F6F6F6] rounded-lg p-6 flex flex-col items-center">
          <h2 className="text-[#023844] font-semibold mb-4">TipJAR QR Code</h2>

          <div className="relative mb-4">
            <QRCode
              value={`${stagingUrl}${activeEntity?.shukran_id}`}
              size={200}
              fgColor="#CECECE"
              style={{ margin: "0 auto" }}
              className="bg-white p-2"
            />
          </div>

          <p className="text-[#023844] text-sm">
            TipJAR ID: {activeEntity?.shukran_id}
          </p>
        </div>

        {/* Inactive Notice */}
        <div className="w-full max-w-md mt-6 flex flex-col items-center">
          <div className="bg-[#FFF1D6] rounded-lg p-4 w-full flex flex-col items-center gap-3 mb-4">
            <div className="flex items-center gap-2">
              <AlertCircle className="text-[#EE5582]" size={20} />
              <span className="text-[#EE5582] font-medium">
                Pending verification!
              </span>
            </div>
            <p className="text-center text-sm text-gray-600">
              Your business details are being processed for activation.
            </p>
          </div>

          {/* Status Check Button */}
          <div className="w-full mt-auto pb-6">
            <Button
              className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white font-bold py-3 px-4 rounded-full shadow-md h-[60px]"
              onClick={handleCheckStatus}
              disabled={isGettingSingleEntity}
            >
              {isGettingSingleEntity ? (
                <div className="w-full flex flex-row items-center gap-2 justify-center">
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  <span className="text-white">CHECKING...</span>
                </div>
              ) : (
                <span className="text-white">CHECK STATUS</span>
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Pending Dialog */}
      <Dialog open={showPendingDialog} onOpenChange={setShowPendingDialog}>
        <DialogContent className="sm:max-w-[425px] flex flex-col items-center text-center p-6 rounded-lg">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold">
              Approval in Progress
            </DialogTitle>
          </DialogHeader>
          <div className="rounded-full bg-[#FF8400]/20 p-3 mb-4">
            <div className="rounded-full bg-[#FFA500] p-2">
              <Clock className="h-6 w-6 text-white" />
            </div>
          </div>
          <p className="text-gray-600 mb-6">
            Your submission is currently under review. This process may take up to
            24-48 hours. We'll notify you once the approval is complete.
          </p>
          <Button
            className="bg-[#50A2A7] hover:bg-[#408b8f] text-white w-full rounded-full h-[50px]"
            onClick={() => setShowPendingDialog(false)}
          >
            Got it
          </Button>
        </DialogContent>
      </Dialog>

      {/* Approved Dialog */}
      <Dialog open={showApprovedDialog} onOpenChange={setShowApprovedDialog}>
        <DialogContent className="sm:max-w-[425px] flex flex-col items-center text-center p-6 rounded-lg">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold">
              Approval Successful!
            </DialogTitle>
          </DialogHeader>
          <div className="rounded-full bg-[#50A2A7]/10 p-3 mb-4">
            <div className="rounded-full bg-[#50A2A7] p-2">
              <Check className="h-6 w-6 text-white" />
            </div>
          </div>
          <p className="text-gray-600 mb-6">
            Congratulations! Your submission has been approved. You can now proceed
            to enjoy all the features of the TipJAR.
          </p>
          <Button
            className="bg-[#F2C773] hover:bg-[#e0b665] text-white w-full rounded-full h-[50px]"
            onClick={() => {
              setShowApprovedDialog(false);
              navigate("/tipjar/");
            }}
          >
            Get Started
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EntityInReview;